<header>
	<nav>
		<ul>
			<li><a href="/">Home</a></li>
			<li>
				<a href="/#our-story">Our story</a>
			</li>
			<li>
				<a href="/#wedding">Wedding</a>
			</li>
		</ul>
	</nav>
</header>

<style lang="postcss">
	header {
		position: sticky;
		top: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		background-color: var(--primary-color);
		z-index: 1000;
	}

	nav {
		display: flex;
		padding: 10px;
		justify-content: center;
	}

	ul {
		position: relative;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		list-style: none;
		gap: 50px;
	}

	li {
		position: relative;
		height: 100%;
	}

	nav a {
		display: flex;
		align-items: center;
		color: var(--text-color);
		font-size: 40px;
		line-height: 40px;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	@media (max-width: 600px) {
		nav {
			ul {
				gap: 30px;
			}

			a {
				font-size: 32px;
			}
		}
	}

	@media (max-width: 400px) {
		header {
			width: 100vw;
		}
		nav {
			background-color: var(--primary-color);
			width: 100%;

			a {
				font-size: 28px;
			}
		}
	}
</style>
