<script lang="ts">
	import "$lib/app.css";
	import "$lib/common.css";

	import { Footer } from "shared";

	import { dev } from "$app/environment";
	import FooterUs from "$lib/components/FooterUs.svelte";
	import Header from "$lib/components/Header.svelte";
</script>

<svelte:head>
	{#if !dev}
		<script
			defer
			data-domain="sreetamandareena.com"
			src="/stats/js/script.js"
			data-api="/api/plsbl"
		></script>
	{/if}
</svelte:head>

<Header />

<main class="wrapper">
	<slot />
</main>

<FooterUs />
<Footer />

<style lang="postcss">
	main {
		display: grid;
		grid-template-columns: 1fr min(1000px, calc(100vw - 30px)) 1fr;
		grid-column-gap: 15px;
		justify-items: center;

		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	:global(.wrapper > *) {
		grid-column: 2;
	}
</style>
