<section class="footer-image-container">
	<div class="footer-gradient" />
	<img
		src="/images/us.jpeg"
		alt="Areena and Sreetam side by side, looking at each other against a scenic backdrop"
		loading="lazy"
		width="1440"
		height="1920"
	/>
</section>

<style lang="postcss">
	.footer-image-container {
		--shadow-color: rgb(137, 184, 255);

		grid-column: 1 / 4;
		margin-top: 200px;
		height: fit-content;
		width: 100vw;
		background-color: var(--shadow-color);

		display: grid;
		justify-items: center;
	}

	.footer-gradient {
		height: 350px;
		width: 100vw;
		background: linear-gradient(0deg, var(--shadow-color), var(--primary-color));
	}

	.footer-image-container img {
		display: block;
		width: 100vw;
		max-width: 1440px;
		height: auto;
	}
</style>
